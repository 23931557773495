import cx from 'classnames'
import Color from 'color'
import { type Property } from 'csstype'
import { useCallback } from 'react'

export interface SmallGraphicProps {
  backgroundColor?: Property.BackgroundColor
  backgroundImage?: Property.BackgroundImage
  selected?: boolean
  error?: boolean
  disabled?: boolean
  onClick?: () => void
  className?: string
}

const SmallGraphic = ({
  backgroundColor,
  backgroundImage,
  selected,
  error = false,
  disabled = false,
  onClick,
  className,
}: SmallGraphicProps) => {
  const isBackgroundColorLight = Color(backgroundColor).isLight()

  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }

    if (onClick) {
      onClick()
    }
  }, [disabled, onClick])

  return (
    <div
      onClick={handleClick}
      className={cx(
        'relative inline-flex items-center justify-center w-[40px] h-[40px]',
        {
          'cursor-pointer': !disabled,
          'opacity-30 cursor-not-allowed': disabled,
        },
        className,
      )}
    >
      <div
        className={cx('w-[32px] h-[32px]', {
          'bg-no-repeat bg-center': !!backgroundImage,
        })}
        style={{
          backgroundColor,
          backgroundImage: !!backgroundImage
            ? `url(${backgroundImage})`
            : undefined,
        }}
      />

      <div
        className={cx('absolute top-0 left-0 w-full h-full z-10 border', {
          'border-error': error,
          'border-black border-[3px]': !error && selected,
          'border-gray-400': !error && !selected && isBackgroundColorLight,
        })}
      />
    </div>
  )
}

export default SmallGraphic
