import axios from 'axios'

import { type Locale } from '@lib/language'
import { type SanityCombinedListingProductResponse } from './types'

/**
 * Fetches combined listing product by its ID.
 */
export const getCombinedListingProduct = async (
  locale: Locale,
  productId: number,
) => {
  try {
    const response = await axios.get<SanityCombinedListingProductResponse>(
      '/api/sanity/combined-listing-product',
      {
        params: {
          product_id: productId,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      },
    )

    if (response.status !== 200) {
      throw new Error(response.data.error)
    }

    if (!response.data.product) {
      throw new Error('Product not found')
    }

    return response.data.product
  } catch (error) {
    console.log(error)
  }
}
