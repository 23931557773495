import { type SanityCombinedListingProductFragment } from '@data/sanity/queries/types/product'
import { filterProductsBySize } from './size'
import { type DropdownOptionWithColor } from './types'

/**
 * Gets dropdown input options from products.
 */
export const getCombinedListingProductDropdownOptions = (
  products: SanityCombinedListingProductFragment[],
  size?: string,
  noSelectionTitle?: string,
) => {
  const productOptions: DropdownOptionWithColor[] = []

  if (noSelectionTitle) {
    productOptions.push({
      value: '',
      title: noSelectionTitle,
    })
  }

  products.filter(filterProductsBySize(size)).forEach((product) => {
    productOptions.push({
      value: `${product.productID}`,
      title: product.title,
      // Add color value, if product combined listing type is frame
      color: product.type === 'frame' ? product.frameColor : undefined,
    })
  })

  return productOptions
}
