import { useState } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

import InputDropdownWithManagedState, {
  type InputDropdownWithManagedStateProps,
} from './with-managed-state'

type InputDropdownWithFormRegisterProps = Pick<
  InputDropdownWithManagedStateProps,
  | 'options'
  | 'errorMessage'
  | 'label'
  | 'placeholder'
  | 'borderBottom'
  | 'className'
> & {
  formRegister: UseFormRegisterReturn
  defaultValue?: string
}

const InputDropdownWithFormRegister = ({
  formRegister,
  options,
  errorMessage,
  label,
  defaultValue,
  placeholder,
  borderBottom,
  className,
}: InputDropdownWithFormRegisterProps) => {
  const [value, setValue] = useState(defaultValue)

  return (
    <InputDropdownWithManagedState
      ref={formRegister.ref}
      options={options}
      value={value}
      setValue={setValue}
      errorMessage={errorMessage}
      name={formRegister.name}
      label={label}
      placeholder={placeholder}
      borderBottom={borderBottom}
      onChange={formRegister.onChange}
      onBlur={formRegister.onBlur}
      className={className}
    />
  )
}

export default InputDropdownWithFormRegister
