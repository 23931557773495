import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { useUser } from '@lib/auth'
import { LanguageContext } from '@lib/language-context'
import { getRandomString } from '@lib/helpers'
import { colorOptionNames } from '@lib/product'
import {
  getCombinedListingCartAttributes,
  getCombinedListingProduct,
  ProductCombinedListingContext,
} from '@lib/product/combined-listing'
import { StringsContext } from '@lib/strings-context'

import CombinedListingProductSelect from '@blocks/product/combined-listing-product-select'
import ProductActions from '@blocks/product/product-actions'

interface QuickAddModalCombinedListingProductControlsProps {
  product: SanityProductFragment
}

const QuickAddModalCombinedListingProductControls = ({
  product,
}: QuickAddModalCombinedListingProductControlsProps) => {
  const { locale } = useContext(LanguageContext)
  const strings = useContext(StringsContext)
  const {
    combinedListingActiveVariants,
    combinedListingConfiguration,
    combinedListingActivePassepartoutHoleSize,
    setCombinedListingMainProductId,
    setCombinedListingConfiguration,
    setCombinedListingDefaultsConfiguration,
    setCombinedListingStateManagerType,
    clearCombinedListing,
  } = useContext(ProductCombinedListingContext)

  const { user } = useUser()

  const [isError, setIsError] = useState(false)

  const configurationLoadedRef = useRef(false)

  // Frame color
  const frameColor = useMemo(() => {
    const colorOption = product?.options?.find((option) =>
      colorOptionNames.includes(option.name),
    )

    return colorOption?.values?.[0]
  }, [product])

  // Load configuration when opening modal
  useEffect(() => {
    if (product && !configurationLoadedRef.current) {
      configurationLoadedRef.current = true

      const loadCombinedListingProduct = async () => {
        try {
          const newProduct = await getCombinedListingProduct(
            locale,
            product.productID,
          )

          if (!newProduct) {
            throw new Error('Product not found')
          }

          setIsError(false)

          // Load configuration
          setCombinedListingStateManagerType('memory')
          setCombinedListingMainProductId(product.productID)
          setCombinedListingConfiguration(newProduct.productCombinedListing)
          setCombinedListingDefaultsConfiguration(
            newProduct.productCombinedListingDefaults,
          )

          // Clear combined listing selection
          clearCombinedListing()
        } catch (error) {
          console.log(error)
          setIsError(true)
        }
      }
      loadCombinedListingProduct()
    }

    return () => {
      // Unload configuration
      setCombinedListingConfiguration()
      setCombinedListingDefaultsConfiguration()
      configurationLoadedRef.current = false

      // Clear combined listing selection
      clearCombinedListing()
    }
  }, [
    clearCombinedListing,
    locale,
    product,
    setCombinedListingConfiguration,
    setCombinedListingDefaultsConfiguration,
    setCombinedListingMainProductId,
    setCombinedListingStateManagerType,
  ])

  if (!product) {
    return null
  }

  const isUserLoading = typeof user === 'undefined'
  const isUserLoggedIn = !!user?.isLoggedIn

  const combinedListingVariantIds =
    combinedListingActiveVariants?.map(
      (activeVariant) => activeVariant.variantID,
    ) ?? []
  const cartAttributes = getCombinedListingCartAttributes(
    combinedListingVariantIds,
    getRandomString(),
    combinedListingActivePassepartoutHoleSize,
  )

  return (
    <>
      {isError && <div className="p-5">{strings.combinedListingErrorText}</div>}
      {!combinedListingConfiguration && !isError && (
        <div className="p-5">{strings.combinedListingLoadingText}</div>
      )}
      {!!combinedListingConfiguration && !isError && (
        <>
          <div className="overflow-y-scroll no-scrollbar px-5 pb-5">
            <CombinedListingProductSelect
              onMainProductChange={(product) =>
                setCombinedListingMainProductId(product.productID)
              }
              frameColor={frameColor}
            />
          </div>

          {!isUserLoading && !!isUserLoggedIn && (
            <div className="p-5">
              <ProductActions
                variantIds={combinedListingVariantIds}
                inStock
                cartAttributes={cartAttributes}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default QuickAddModalCombinedListingProductControls
