import { motion, AnimatePresence } from 'framer-motion'
import { type BaseSyntheticEvent, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

import { formAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language-context'
import { addEmailToWaitlist } from '@lib/services'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import Button, { ButtonVariant } from '@components/buttons/button'
import InputField from '@components/input-field/with-form-register'

interface ProductWaitlistProps {
  service: 'klaviyo'
  variantId: number
}

interface ProductWaitlistFormValues {
  fullname: string
  email: string
}

const ProductWaitlist = ({ service, variantId }: ProductWaitlistProps) => {
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)
  const { settings } = useContext(SiteContext)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ProductWaitlistFormValues>()
  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })

  const resetForm = (event: BaseSyntheticEvent) => {
    event.preventDefault()

    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const onSubmit = async (
    productWaitlistFormValues: ProductWaitlistFormValues,
    event?: BaseSyntheticEvent,
  ) => {
    event?.preventDefault()

    setIsSuccess(false)
    setIsError(false)

    if (!variantId) {
      setIsError(true)
      return
    }

    setIsSubmitting(true)

    try {
      await addEmailToWaitlist(
        locale,
        service,
        variantId,
        productWaitlistFormValues.email,
        productWaitlistFormValues.fullname,
      )
      setIsSuccess(true)
    } catch (error) {
      console.log(error)
      setIsError(true)
    }

    setIsSubmitting(false)
  }

  if (!service) {
    return null
  }

  if (service === 'klaviyo' && !settings?.klaviyoAccountID) {
    return (
      <Button
        disabled
        variant={ButtonVariant.OUTLINED}
        className="w-full sm:w-auto min-w-[240px]"
      >
        {strings.productOutOfStock}
      </Button>
    )
  }

  return (
    <div>
      <AnimatePresence mode="wait" initial={false}>
        {!isError && !isSuccess && (
          <motion.form
            key="form"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnimation}
            className="form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              type="text"
              autoComplete="off"
              className="hidden"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
            />

            <div className="flex flex-wrap items-center">
              <InputField
                type="email"
                formRegister={emailRegister}
                placeholder={strings.emailAddress}
                errorMessage={errors.email?.message}
                borderBottom
                className="mt-4 mr-4 min-w-full sm:min-w-[300px]"
              />

              <Button
                disabled={isSubmitting}
                variant={ButtonVariant.FILLED}
                className="mt-4"
              >
                {isSubmitting
                  ? strings.buttonWaiting
                  : strings.productWaitlistSubmit}
              </Button>
            </div>
          </motion.form>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnimation}
          >
            <Alert success onClick={resetForm}>
              <p className="mb-0">{strings.productWaitlistSuccess}</p>
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={formAnimation}
          >
            <Alert
              error
              buttonText={strings.buttonTryAgain}
              onClick={resetForm}
            >
              <p className="mb-0">{strings.productWaitlistError}</p>
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ProductWaitlist
