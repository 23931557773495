import { useContext, useState } from 'react'

import { type AttributeInput } from '@data/shopify/storefront/types'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { CartContext } from '@lib/cart/context'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import { ButtonVariant } from '@components/buttons/button'
import SimplePortableText from '@components/simple-portable-text'
import ProductAddToCart from './product-add-to-cart'
import ProductCounter from './product-counter'
import ProductWaitlist from './product-waitlist'

interface ProductActionsProps {
  variantIds: number[]
  inStock: boolean
  cartAttributes?: AttributeInput[]
}

const ProductActions = ({
  variantIds,
  inStock,
  cartAttributes,
}: ProductActionsProps) => {
  const strings = useContext(StringsContext)
  const { addItemsToCart } = useContext(CartContext)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)

  const [quantity, setQuantity] = useState(1)
  const [isAddToCartError, setIsAddToCartError] = useState(false)

  const handleAddToCart = async () => {
    setIsAddToCartError(false)

    const isSuccessful = await addItemsToCart(
      variantIds.map((variantId) => ({
        id: variantId,
        quantity,
        attributes: cartAttributes,
      })),
    )

    setIsAddToCartError(!isSuccessful)

    if (isSuccessful) {
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart)
    }
  }

  if (!inStock && variantIds.length === 1) {
    return (
      <ProductWaitlist
        // TODO: Move to product hero component setting.
        service="klaviyo"
        variantId={variantIds[0]}
      />
    )
  }

  return (
    <div className="flex flex-col gap-y-5 items-baseline w-full text-center">
      <ProductCounter max={10} onUpdate={setQuantity} />

      <ProductAddToCart
        variant={ButtonVariant.FILLED}
        className="max-w-sm w-full"
        onClick={handleAddToCart}
      >
        {strings.buttonAddToCart}
      </ProductAddToCart>

      {isAddToCartError && (
        <div key="error">
          <Alert error>
            <SimplePortableText
              className="rc rc-alert rc-error"
              content={strings.cartAddToCartErrorMessage}
            />
          </Alert>
        </div>
      )}
    </div>
  )
}

export default ProductActions
