import { type SanityCombinedListingDefaultsConfiguration } from '@data/sanity/queries/types/product'
import { type SanityCombinedListingConfiguration } from '@data/sanity/queries/types/product'
import { getCombinedListingProductsBySize } from './product'
import {
  type CombinedListingDefaultFrame,
  type CombinedListingDefaultOptions,
} from './types'

/**
 * Gets combined listing product default values for a specific size.
 */
export const getCombinedListingProductDefaultValues = (
  size: string,
  combinedListingConfiguration: SanityCombinedListingConfiguration,
  combinedListingDefaultsConfiguration?: SanityCombinedListingDefaultsConfiguration,
) => {
  if (!combinedListingConfiguration || !size) {
    return {}
  }

  // Get available product IDs
  const combinedListing = getCombinedListingProductsBySize(
    combinedListingConfiguration.frame ?? [],
    combinedListingConfiguration.glass ?? [],
    combinedListingConfiguration.backboard ?? [],
    combinedListingConfiguration.passepartout ?? [],
    combinedListingConfiguration.spacer ?? [],
  )

  if (!combinedListing[size]) {
    return {}
  }

  const availableGlassProductIds = combinedListing[size].glass.map(
    (product) => product.productID,
  )
  const availableBackboardProductIds = combinedListing[size].backboard.map(
    (product) => product.productID,
  )
  const availablePassepartoutProductIds = combinedListing[
    size
  ].passepartout.map((product) => product.productID)
  const availableSpacerProductIds = combinedListing[size].spacer.map(
    (product) => product.productID,
  )

  // Get default product IDs by type
  const combinedListingDefaultOptions: CombinedListingDefaultOptions = {
    frame: [],
    glass:
      combinedListingConfiguration.defaultGlassProducts &&
      combinedListingConfiguration.defaultGlassProducts.length > 0
        ? combinedListingConfiguration.defaultGlassProducts
        : combinedListingDefaultsConfiguration?.defaultGlassProducts ?? [],
    backboard:
      combinedListingConfiguration.defaultBackboardProducts &&
      combinedListingConfiguration.defaultBackboardProducts.length > 0
        ? combinedListingConfiguration.defaultBackboardProducts
        : combinedListingDefaultsConfiguration?.defaultBackboardProducts ?? [],
    passepartout:
      combinedListingConfiguration.defaultPassepartoutProducts &&
      combinedListingConfiguration.defaultPassepartoutProducts.length > 0
        ? combinedListingConfiguration.defaultPassepartoutProducts
        : combinedListingDefaultsConfiguration?.defaultPassepartoutProducts ??
          [],
    spacer:
      combinedListingConfiguration.defaultSpacerProducts &&
      combinedListingConfiguration.defaultSpacerProducts.length > 0
        ? combinedListingConfiguration.defaultSpacerProducts
        : combinedListingDefaultsConfiguration?.defaultSpacerProducts ?? [],
  }

  // Get default product IDs that are available
  const defaultGlassProductId = combinedListingDefaultOptions.glass.find(
    (productId) => availableGlassProductIds.includes(productId),
  )
  const defaultBackboardProductId =
    combinedListingDefaultOptions.backboard.find((productId) =>
      availableBackboardProductIds.includes(productId),
    )
  const defaultPassepartoutProductId =
    combinedListingDefaultOptions.passepartout.find((productId) =>
      availablePassepartoutProductIds.includes(productId),
    )
  const defaultSpacerProductId = combinedListingDefaultOptions.spacer.find(
    (productId) => availableSpacerProductIds.includes(productId),
  )

  const combinedListingDefaultFrame: CombinedListingDefaultFrame = {
    glassProductId: defaultGlassProductId,
    backboardProductId: defaultBackboardProductId,
    passepartoutProductId: defaultPassepartoutProductId,
    spacerProductId: defaultSpacerProductId,
  }

  return combinedListingDefaultFrame
}
