import cx from 'classnames'
import { type BaseSyntheticEvent, useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'

import { type SearchFormValues } from '@lib/search'
import { SearchContext } from '@lib/search-context'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'
import InputField from '@components/input-field/with-form-register'

interface SearchFieldProps {
  className?: string
}

const SearchField = ({ className }: SearchFieldProps) => {
  const {
    queryInputValue,
    searchDrawer,
    setQueryInputValue,
    setQuerySuggestionValue,
    toggleSearchDrawer,
  } = useContext(SearchContext)
  const { toggleMegaNavigation, toggleMobileMenu } = useContext(SiteContext)
  const strings = useContext(StringsContext)

  const { handleSubmit, register, setFocus } = useForm<SearchFormValues>()
  const queryRegister = register('query', {
    onChange: (event) => setQueryInputValue(event.target.value),
  })

  const onSubmit = useCallback(
    (searchFormValues: SearchFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      if (!queryInputValue) {
        // Focus search input
        setFocus('query')
        return
      }

      setQuerySuggestionValue(queryInputValue)

      // Open search drawer
      toggleMobileMenu(false)
      toggleMegaNavigation(false)
      toggleSearchDrawer(true)
    },
    [
      queryInputValue,
      setFocus,
      setQuerySuggestionValue,
      toggleMegaNavigation,
      toggleMobileMenu,
      toggleSearchDrawer,
    ],
  )

  return (
    <>
      {/* Desktop form */}
      <form
        className={cx(
          'hidden sm:flex w-[270px] items-center rounded-full pl-5 pr-4 bg-gray-100 h-11',
          className,
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          type="text"
          formRegister={queryRegister}
          placeholder={strings.searchPlaceholder}
          noBorder
          className="flex-auto bg-gray-100"
        />

        {!!queryInputValue && (
          <>
            <button
              type="button"
              onClick={(event) => {
                event.preventDefault()
                setQueryInputValue('')
              }}
              className={cx(
                'inline-flex flex-none mr-1 ml-1',
                'items-center disabled:opacity-50 transition-all hover:opacity-60',
                'text-sm text-current font-normal',
              )}
            >
              <Icon
                id="search-field-clear-icon"
                name="Cross"
                className="opacity-30"
              />
            </button>
            <div className="w-0 h-[24px] flex-none ml-1 mr-1 border-r" />
          </>
        )}
        <button
          type="submit"
          className={cx(
            'inline-flex flex-none',
            'items-center disabled:opacity-50 transition-all hover:opacity-60',
            'text-xl text-current font-normal',
          )}
        >
          <Icon id="search-field-search-icon" name="Search" />
        </button>
      </form>

      {/* Mobile button */}
      <div className="block sm:hidden">
        <button
          type="button"
          onClick={() => {
            // Toggle search drawer
            toggleMobileMenu(false)
            toggleMegaNavigation(false)
            toggleSearchDrawer(!searchDrawer.isOpen)
          }}
          className={cx(
            'inline-flex rounded-full border-[1px] leading-[1.25] bg-transparent border-gray-300 text-black p-2 md:px-5.5 md:py-2.5 md:gap-x-2',
            'justify-center items-center disabled:opacity-50 transition-all hover:opacity-60',
            'text-xl text-current font-normal whitespace-nowrap text-center',
          )}
        >
          <Icon id="search-field-search-icon-mobile" name="Search" />
        </button>
      </div>
    </>
  )
}

export default SearchField
